import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

console.log('Supabase Environment Check:', {
  hasUrl: !!supabaseUrl,
  hasKey: !!supabaseKey,
  nodeEnv: process.env.NODE_ENV
});

// Create client only if credentials are available
let supabase = null;

if (supabaseUrl && supabaseKey) {
  supabase = createClient(supabaseUrl, supabaseKey);
}

// Export a function that checks for client availability
export const getSupabase = () => {
  if (!supabase) {
    console.warn('Supabase client not initialized - form submissions will be logged to console only');
  }
  return supabase;
};

// Export connection check function
export const checkSupabaseConnection = async () => {
  const client = getSupabase();
  if (!client) return false;

  try {
    const { data, error } = await client
      .from('form_submissions')
      .select('*')
      .limit(1);

    if (error) {
      console.error('Supabase connection check failed:', error);
      return false;
    }

    console.log('Supabase connection successful, table exists');
    return true;
  } catch (error) {
    console.error('Supabase connection check error:', error);
    return false;
  }
};
