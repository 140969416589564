const isProduction = process.env.NODE_ENV === 'production';
const isMainDomain = window.location.hostname === 'muryo.io';
const hasGtag = typeof window.gtag !== 'undefined';

export const shouldTrack = isProduction && isMainDomain && hasGtag;

export const trackEvent = (eventName, eventLabel = '') => {
  if (!shouldTrack) {
    console.log('Event tracking disabled:', { eventName, eventLabel });
    return;
  }

  window.gtag('event', eventName, {
    event_category: 'engagement',
    event_label: eventLabel
  });
  console.log('Event tracked:', { eventName, eventLabel });
};