import React from 'react';
import LandingPage from './components/LandingPage';

const App = () => {
  console.log('FINREFARM: App rendering');
  
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default App;